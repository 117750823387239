<template>
  <div>
    <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px">
      <el-form-item :label="$t('supplier.qymc')" prop="company">
        <el-input v-model="form.company" :placeholder="$t('supplier.qsrqymc')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('supplier.xm')" prop="name">
        <el-input v-model="form.name" :placeholder="$t('supplier.qsrxm')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('supplier.yx')" prop="email">
        <el-input v-model="form.email" :placeholder="$t('supplier.qsuyx')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('supplier.dh')" prop="phone">
        <el-input v-model="form.phone" :placeholder="$t('supplier.qsrdh')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('supplier.nr')" prop="details">
        <el-input type="textarea" :rows="6" v-model="form.details" :placeholder="$t('supplier.qsrnr')"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('ruleForm')">{{$t('supplier.tj')}}</el-button>
        <el-button @click="resetForm('ruleForm')">{{$t('supplier.cz')}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'BbjtwebsiteSupplier',

  data() {
    let phone = (rule, value, callback) => {
      let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!myreg.test(value)) {
        callback(this.$t('supplier.qtxzqddhhm'))
      } else {
        callback()
      }
    };
    let email = (rule, value, callback) => {
      let myreg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (!myreg.test(value)) {
        callback(this.$t('supplier.qtxzqdyx'))
      } else {
        callback()
      }
    };
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        details: '',
        company: ''
      },
      rules: {
        company: [
          { required: true, message: this.$t('supplier.qcrqymc'), trigger: 'blur' },
        ],
        name: [
          { required: true, message: this.$t('supplier.qcrxm'), trigger: 'blur' },
        ],
        phone: [
          {required: true, message:  this.$t('supplier.qcrsjh'), trigger: 'blur'},
          {validator: phone, trigger: 'change'},
        ],
        email: [
          {required: true, message:  this.$t('supplier.qsuyx'), trigger: 'blur'},
          {validator: email, trigger: 'change'},
        ],
        details: [
          { required: true, message:  this.$t('supplier.qcrnr'), trigger: 'blur' }
        ]
      }
    };
  },
  props:{
    clientType: {
      type: Number,
      default: null,
    },
  },

  mounted() {
  },
  watch: {
    clientType: function (val) {
      this.resetForm('ruleForm')
    }
  },

  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addleaveMassage()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async addleaveMassage() {
      if(this.clientType == 1) {
        this.form.client_type = 1
      } else {
        this.form.client_type = 2
      }
      let res = await this.api.post(this.apiPath.leaveMassage,this.form);
      console.log(res);
      if(res.code == 200) {
        this.$message({
          message: res.message,
          type: 'success'
        });
      }else {
        this.$message({
          message: res.message,
          type: 'warning'
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
};
</script>

<style lang="scss" scoped>

</style>